AOS.init({ disable: 'mobile' });

const isFr = document.querySelector('html').lang === 'fr-FR';

if (isFr) {
  const breadcrumbLabel = document.querySelector(
    '.rank-math-breadcrumb a:first-child'
  );

  if (breadcrumbLabel) breadcrumbLabel.textContent = 'Accueil';
}
